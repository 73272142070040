import Papa from 'papaparse'
import React from 'react'
import Div100vh from 'react-div-100vh'
import { useTranslation } from '../../plugins/gatsby-plugin-react-i18next'
import { dialogType } from '../../types/app'
import FormIssue from '../components/forms/form-issue'
import ModalFeedback from '../components/forms/modal-form'
import Map from '../components/map'
import { dispatch, useGlobalState } from '../state'

const IndexPage: React.FC = () => {
  const { t } = useTranslation('translation')

  const [dialogState] = useGlobalState('dialogState')

  React.useEffect(() => {
    Papa.parse(process.env.GATSBY_SPREADSHEET_URL!, {
      download: true,
      header: true,
      complete: (results: any) => {
        const data = results.data
        console.log(results)
        dispatch({ type: 'setPalletDataLoading', value: false })
        dispatch({ type: 'setPalletData', value: data })
      }
    })
  }, [])

  const handleDialogClose = (dialog: dialogType) => {
    console.log('close dialog', dialog)
    if (dialogState === dialog) {
      dispatch({
        type: 'setDialogState',
        value: null
      })
    }
  }

  return (
    <Div100vh>
      <Map fullscreen />
      <>
        <ModalFeedback
          isOpen={dialogState === 'dialogFull'}
          onClose={() => {
            handleDialogClose('dialogFull')
          }}
        >
          <FormIssue
            formName="pallet-full"
            messageSubject="A pallet is full!"
            headline={t('A pallet is full?')}
            copytext={t(
              'Please tell us the location of the pallet and leave your eMail in case we need to check back.'
            )}
          />
        </ModalFeedback>

        <ModalFeedback
          isOpen={dialogState === 'dialogBroken'}
          onClose={() => {
            handleDialogClose('dialogBroken')
          }}
        >
          <FormIssue
            formName="pallet-broken"
            messageSubject="A pallet is broken!"
            headline={t('A pallet is broken?')}
            copytext={t(
              'Please tell us the location of the pallet and leave your eMail in case we need to check back.'
            )}
          />
        </ModalFeedback>

        <ModalFeedback
          isOpen={dialogState === 'dialogGone'}
          onClose={() => {
            handleDialogClose('dialogGone')
          }}
        >
          <FormIssue
            formName="pallet-gone"
            messageSubject="A pallet is gone!"
            headline={t('A pallet is gone?')}
            copytext={t(
              'Please tell us the location of the missing pallet and leave your eMail in case we need to check back.'
            )}
          />
        </ModalFeedback>
      </>
    </Div100vh>
  )
}

export default IndexPage
